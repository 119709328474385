<template>
    <div class="wrapper">
        <!-- 应付情况 -->
        <table class="custom-table">
            <tbody>
                <!-- 直接材料 -->
                <tr>
                    <td colspan="8">
                        <table class="custom-table" v-for="(term,index) in data.payableTypes" :key="index">
                            <tbody class="border6">
                                <tr>
                                    <td
                                        rowspan="17"
                                        class="custom-th classify border-top-none"
                                        :class="{'custom-th-odd':index%2===0,'custom-th-even':index%2===1}"
                                    >
                                        {{ term.payableType.type_name }}
                                    </td>
                                    <td colspan="2" class="custom-th border-top-none" style="width:3.2rem;">
                                        期初余额（万元）
                                    </td>
                                    <td colspan="2" class="border-top-none">
                                        <el-input
                                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                            :disabled="type=='approval'"
                                            v-model="term.payableType.balance_begin"
                                            @blur="computTotalOther"
                                            :class="{'red': parseFloat(term.payableType.balance_begin)<0}"
                                        ></el-input>
                                    </td>
                                    <td class="custom-th border-top-none" colspan="2">
                                        其中:中联内应付
                                    </td>
                                    <td class="border-top-none">
                                        <el-input
                                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                            :disabled="type=='approval'"
                                            v-model="term.payableType.balance_begin_inner"
                                            @blur="computTotalOther"
                                            :class="{'red': parseFloat(term.payableType.balance_begin_inner)<0}"
                                        ></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="custom-th">
                                        实际采购（万元）
                                    </td>
                                    <td colspan="2">
                                        <el-input
                                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                            :disabled="type=='approval'"
                                            v-model="term.payableType.procure_actual"
                                            @blur="computTotalOther"
                                            :class="{'red': parseFloat(term.payableType.procure_actual)<0}"
                                        ></el-input>
                                    </td>
                                    <td class="custom-th" colspan="2">
                                        其中:中联内采购
                                    </td>
                                    <td>
                                        <el-input
                                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                            :disabled="type=='approval'"
                                            v-model="term.payableType.procure_actual_inner"
                                            @blur="computTotalOther"
                                            :class="{'red': parseFloat(term.payableType.procure_actual_inner)<0}"
                                        ></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2"></td>
                                    <td class="custom-th">
                                        本月
                                    </td>
                                    <td class="custom-th" style="width:1.4rem;">
                                        本年累计
                                    </td>
                                    <td class="custom-th" colspan="2">
                                        本年计划
                                    </td>
                                    <td class="custom-th">
                                        本年完成
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="custom-th">
                                        实际付款（万元）
                                    </td>
                                    <td :class="{'red': parseFloat(term.payableType.pay)<0}">
                                        {{ term.payableType.pay }}
                                    </td>
                                    <td :class="{'red': parseFloat(term.payableType.pay_year)<0}">
                                        {{ term.payableType.pay_year }}
                                    </td>
                                    <td colspan="2">
                                        <el-input
                                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                            :disabled="type=='approval'"
                                            v-model="term.payableType.plan_year"
                                            @blur="computType('totalPlanFinish',null,term)"
                                            :class="{'red': parseFloat(term.payableType.plan_year)<0}"
                                        ></el-input>
                                    </td>
                                    <td :class="{'red': parseFloat(term.payableType.plan_year_finish)<0}">
                                        {{ term.payableType.plan_year_finish }}
                                    </td>
                                </tr>
                                <template v-for="(v,vIndex) in term.payableTypeDetails">
                                    <tr :key="`${v.payable_type}_${v.type_name}_${index}`">
                                        <td rowspan="4" v-if="vIndex===0"></td>
                                        <td class="custom-th">
                                            {{ v.type_name }}
                                        </td>
                                        <td>
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                v-model="v.pay"
                                                @blur="computType('pay',v,term,vIndex)"
                                                :class="{'red': parseFloat(v.pay)<0}"
                                            ></el-input>
                                        </td>
                                        <td :class="{'red': parseFloat(v.pay_year)<0}">
                                            {{ v.pay_year }}
                                        </td>
                                        <td colspan="2">
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                v-model="v.plan_year"
                                                :class="{'red': parseFloat(v.plan_year)<0}"
                                            ></el-input>
                                        </td>
                                        <td :class="{'red': parseFloat(v.plan_year_finish)<0}">
                                            {{ v.plan_year_finish }}
                                        </td>
                                    </tr>
                                </template>
                                <tr>
                                    <td colspan="2" class="custom-th">
                                        其中：中联内付款（万元）
                                    </td>
                                    <td :class="{'red': parseFloat(term.payableType.pay_inner)<0}">
                                        {{ term.payableType.pay_inner }}
                                    </td>
                                    <td :class="{'red': parseFloat(term.payableType.pay_year_inner)<0}">
                                        {{ term.payableType.pay_year_inner }}
                                    </td>
                                    <td colspan="2">
                                        <el-input
                                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                            :disabled="type=='approval'"
                                            v-model="term.payableType.plan_year_inner"
                                            @blur="computType('planFinishInner',null,term)"
                                            :class="{'red': parseFloat(term.payableType.plan_year_inner)<0}"
                                        ></el-input>
                                    </td>
                                    <td :class="{'red': parseFloat(term.payableType.plan_year_finish_inner)<0}">
                                        {{ term.payableType.plan_year_finish_inner }}
                                    </td>
                                </tr>
                                <template v-for="(v,vIndex) in term.payableTypeDetails">
                                    <tr :key="`${v.payable_type}_${v.type_name}_${vIndex}_payInner`">
                                        <td rowspan="4" v-if="vIndex==0"></td>
                                        <td class="custom-th">
                                            {{ v.type_name }}
                                        </td>
                                        <td>
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                v-model="v.pay_inner"
                                                @blur="computType('pay_inner',v,term,vIndex)"
                                                :class="{'red': parseFloat(v.pay_inner)<0}"
                                            ></el-input>
                                        </td>
                                        <td :class="{'red': parseFloat(v.pay_year_inner)<0}">
                                            {{ v.pay_year_inner }}
                                        </td>
                                        <td colspan="2">
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                v-model="v.plan_year_inner"
                                                @blur="computType('plan_year_inner',v,term)"
                                                :class="{'red': parseFloat(v.plan_year_inner)<0}"
                                            ></el-input>
                                        </td>
                                        <td :class="{'red': parseFloat(v.plan_year_finish_inner)<0}">
                                            {{ v.plan_year_finish_inner }}
                                        </td>
                                    </tr>
                                </template>
                                <tr>
                                    <td colspan="2" class="custom-th">
                                        期末余额（万元）
                                    </td>
                                    <td colspan="2" :class="{'red': parseFloat(term.payableType.balance_end)<0}">
                                        {{ term.payableType.balance_end }}
                                    </td>
                                    <td class="custom-th" colspan="2">
                                        其中:中联内应付
                                    </td>
                                    <td>
                                        <el-input
                                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                            :disabled="type=='approval'"
                                            v-model="term.payableType.balance_end_inner"
                                            @blur="computTotalOther"
                                            :class="{'red': parseFloat(term.payableType.balance_end_inner)<0}"
                                        ></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="3" style="width: 0.4rem; padding: 0px 0.1rem;" class="custom-th border6">
                                        付款周期
                                    </td>
                                    <td class="custom-th">
                                        1月内
                                    </td>
                                    <td colspan="2">
                                        <el-input
                                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                            :disabled="type=='approval'"
                                            v-model="term.payableType.paycycle1"
                                            @blur="computTotalOther"
                                            :class="{'red': parseFloat(term.payableType.paycycle1)<0}"
                                        ></el-input>
                                    </td>
                                    <td rowspan="3" style="width:.5rem;" class="border6"></td>
                                    <td class="custom-th">
                                        付款比例（％）
                                    </td>
                                    <td :class="{'red': parseFloat(term.payableType.pay_rate)<0}">
                                        {{ term.payableType.pay_rate }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="custom-th">
                                        3月内
                                    </td>
                                    <td colspan="2">
                                        <el-input
                                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                            :disabled="type=='approval'"
                                            v-model="term.payableType.paycycle3"
                                            @blur="computTotalOther"
                                            :class="{'red': parseFloat(term.payableType.paycycle3)<0}"
                                        ></el-input>
                                    </td>
                                    <td class="custom-th">
                                        应付减少(万元)
                                    </td>
                                    <td :class="{'red': parseFloat(term.payableType.pay_reduce)<0}">
                                        {{ term.payableType.pay_reduce }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="custom-th border6">
                                        6月内
                                    </td>
                                    <td colspan="2" class="border6">
                                        <el-input
                                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                            :disabled="type=='approval'"
                                            v-model="term.payableType.paycycle6"
                                            @blur="computTotalOther"
                                            :class="{'red': parseFloat(term.payableType.paycycle6)<0}"
                                        ></el-input>
                                    </td>
                                    <td class="border6"></td>
                                    <td class="border6"></td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <!-- 总计 -->
                <tr>
                    <td rowspan="17" class="custom-th custom-th-even classify">
                        总计
                    </td>
                    <td colspan="2" class="custom-th">
                        期初余额（万元）
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.payableTotal.total.balance_begin)<0}">
                        {{ data.payableTotal.total.balance_begin }}
                    </td>
                    <td class="custom-th" colspan="2">
                        其中:中联内应付
                    </td>
                    <td :class="{'red': parseFloat(data.payableTotal.total.balance_begin_inner)<0}">
                        {{ data.payableTotal.total.balance_begin_inner }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        实际采购（万元）
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.payableTotal.total.procure_actual)<0}">
                        {{ data.payableTotal.total.procure_actual }}
                    </td>
                    <td class="custom-th" colspan="2">
                        其中:中联内采购
                    </td>
                    <td :class="{'red': parseFloat(data.payableTotal.total.procure_actual_inner)<0}">
                        {{ data.payableTotal.total.procure_actual_inner }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2"></td>
                    <td class="custom-th">
                        本月
                    </td>
                    <td class="custom-th">
                        本年累计
                    </td>
                    <td class="custom-th" colspan="2">
                        本年计划
                    </td>
                    <td class="custom-th">
                        本年完成(%)
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        实际付款（万元）
                    </td>
                    <td :class="{'red': parseFloat(data.payableTotal.total.pay)<0}">
                        {{ data.payableTotal.total.pay }}
                    </td>
                    <td :class="{'red': parseFloat(data.payableTotal.total.pay_year)<0}">
                        {{ data.payableTotal.total.pay_year }}
                    </td>
                    <td colspan="2">
                        <el-input
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :disabled="type=='approval'"
                            v-model="data.payableTotal.total.plan_year"
                            @blur="computTotalDetail('totalPlanFinish',null)"
                            :class="{'red': parseFloat(data.payableTotal.total.plan_year)<0}"
                        ></el-input>
                    </td>
                    <td :class="{'red': parseFloat(data.payableTotal.total.plan_year_finish)<0}">
                        {{ data.payableTotal.total.plan_year_finish }}
                    </td>
                </tr>
                <template v-for="(item, index) in data.payableTotal.details">
                    <tr :key="`${item.type_name}_${index}`">
                        <td rowspan="4" v-if="index==0"></td>
                        <td class="custom-th">
                            {{ item.type_name }}
                        </td>
                        <td :class="{'red': parseFloat(item.pay)<0}">
                            {{ item.pay }}
                        </td>
                        <td :class="{'red': parseFloat(item.pay_year)<0}">
                            {{ item.pay_year }}
                        </td>
                        <td colspan="2">
                            <el-input
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :disabled="type=='approval'"
                                v-model="item.plan_year"
                                @blur="computTotalDetail('plan_year',item)"
                                :class="{'red': parseFloat(item.plan_year)<0}"
                            ></el-input>
                        </td>
                        <td :class="{'red': parseFloat(item.plan_year_finish)<0}">
                            {{ item.plan_year_finish }}
                        </td>
                    </tr>
                </template>
                <tr>
                    <td colspan="2" class="custom-th">
                        其中：中联内付款（万元）
                    </td>
                    <td :class="{'red': parseFloat(data.payableTotal.total.pay_inner)<0}">
                        {{ data.payableTotal.total.pay_inner }}
                    </td>
                    <td :class="{'red': parseFloat(data.payableTotal.total.pay_year_inner)<0}">
                        {{ data.payableTotal.total.pay_year_inner }}
                    </td>
                    <td colspan="2">
                        <el-input
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :disabled="type=='approval'"
                            v-model="data.payableTotal.total.plan_year_inner"
                            @blur="computTotalDetail('planFinishInner',null)"
                            :class="{'red': parseFloat(data.payableTotal.total.plan_year_inner)<0}"
                        ></el-input>
                    </td>
                    <td :class="{'red': parseFloat(data.payableTotal.total.plan_year_finish_inner)<0}">
                        {{ data.payableTotal.total.plan_year_finish_inner }}
                    </td>
                </tr>
                <template v-for="(item, index) in data.payableTotal.details">
                    <tr :key="item.rpd_id">
                        <td rowspan="4" v-if="index==0"></td>
                        <td class="custom-th">
                            {{ item.type_name }}
                        </td>
                        <td :class="{'red': parseFloat(item.pay_inner)<0}">
                            {{ item.pay_inner }}
                        </td>
                        <td :class="{'red': parseFloat(item.pay_year_inner)<0}">
                            {{ item.pay_year_inner }}
                        </td>
                        <td colspan="2">
                            <el-input
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :disabled="type=='approval'"
                                v-model="item.plan_year_inner"
                                @blur="computTotalDetail('plan_year_inner',item)"
                                :class="{'red': parseFloat(item.plan_year_inner)<0}"
                            ></el-input>
                        </td>
                        <td :class="{'red': parseFloat(item.plan_year_finish_inner)<0}">
                            {{ item.plan_year_finish_inner }}
                        </td>
                    </tr>
                </template>
                <tr>
                    <td colspan="2" class="custom-th">
                        期末余额（万元）
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.payableTotal.total.balance_end)<0}">
                        {{ data.payableTotal.total.balance_end }}
                    </td>
                    <td class="custom-th" colspan="2">
                        其中:中联内应付
                    </td>
                    <td :class="{'red': parseFloat(data.payableTotal.total.balance_end_inner)<0}">
                        {{ data.payableTotal.total.balance_end_inner }}
                    </td>
                </tr>
                <tr>
                    <td rowspan="3" style="width:.4rem;padding:0 .1rem;" class="custom-th">
                        付款周期
                    </td>
                    <td class="custom-th">
                        1月内
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.payableTotal.total.paycycle1)<0}">
                        {{ data.payableTotal.total.paycycle1 }}
                    </td>
                    <td rowspan="3" style="width:.5rem;"></td>
                    <td class="custom-th">
                        付款比例（％）
                    </td>
                    <td :class="{'red': parseFloat(data.payableTotal.total.pay_rate)<0}">
                        {{ data.payableTotal.total.pay_rate }}
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        3月内
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.payableTotal.total.paycycle3)<0}">
                        {{ data.payableTotal.total.paycycle3 }}
                    </td>
                    <td class="custom-th">
                        应付减少(万元)
                    </td>
                    <td :class="{'red': parseFloat(data.payableTotal.total.pay_reduce)<0}">
                        {{ data.payableTotal.total.pay_reduce }}
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        6月内
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.payableTotal.total.paycycle6)<0}">
                        {{ data.payableTotal.total.paycycle6 }}
                    </td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
/* eslint-disable */
import util from '@/utils/util';
export default {
    components: {},
    props: {
        reportMonth: {
            type: [Object],
        },
        type: {
            type: [String],
        },
        loadObj: {
            type: [Object],
        },
        typeList: {
            type: [Array],
        },
    },
    data() {
        return {
            data: {
                payableTotal: {
                    total: {},
                    details: [],
                },
                payableTypes: [],
            },
            oldData: {
                payableTotal: {
                    total: {},
                    details: [],
                },
                payableTypes: [],
            },
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 获取指定年月的报表实例信息
        getInstance() {
            return this.$axios.get('/interfaceApi/report/instance/get/yyqk/'
            + this.reportMonth.report_year + '/' + (this.reportMonth.report_month - 1));
        },
        // 获取物资采购上个月数据
        getPayablePreMonth(report_id) {
            return this.$axios.get('/interfaceApi/report/payable/' + report_id);
        },
        // 获取-应付情况
        getPayable() {
            this.$axios
                .get('/interfaceApi/report/payable/' + this.reportMonth.report_id)
                .then(async res => {
                    if (res) {
                        this.data = res;
                        this.oldData = this.oldData = JSON.parse(JSON.stringify(this.data));
                        if (this.type !== 'approval') {
                            if (this.typeList[6].state === 0) {
                                if (this.reportMonth.report_month > 1) {
                                    const instanceRes = await this.getInstance();
                                    if (instanceRes) {
                                        if (instanceRes.report_state === 3) {
                                            const payablePreMonthRes = await this.getPayablePreMonth(instanceRes.report_id);
                                            // eslint-disable-next-line max-depth
                                            if (payablePreMonthRes) {
                                                // eslint-disable-next-line max-depth
                                                res.payableTypes.map((item,index)=>{
                                                    item.payableType.balance_begin = payablePreMonthRes.payableTypes[index].payableType.balance_end;
                                                    item.payableTypeDetails.map((v,vIndex)=>{
                                                        v.plan_year = payablePreMonthRes.payableTypes[index].payableTypeDetails[vIndex].plan_year;
                                                        v.plan_year_inner = payablePreMonthRes.payableTypes[index].payableTypeDetails[vIndex].plan_year_inner;
                                                    });
                                                });
                                                res.payableTotal.total.balance_begin = payablePreMonthRes.payableTotal.total.balance_end;
                                                res.payableTotal.details.map((totalItem,index)=>{
                                                    totalItem.plan_year = payablePreMonthRes.payableTotal.details[index].plan_year;
                                                    totalItem.plan_year_inner = payablePreMonthRes.payableTotal.details[index].plan_year_inner;
                                                });

                                            }
                                        }
                                    } 
                                } 
                            }
                        }
                        
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存-应付情况
        save() {
            const newData = {
                total: null,
                totalDetails: [],
                payableTypes: [],
                payableTypeDetails: [],
            };
            const _this = this;
            const totalB = util.Compare(this.oldData.payableTotal.total, this.data.payableTotal.total);
            if (!totalB) {
                newData.total = this.data.payableTotal.total;
            }

            this.oldData.payableTotal.details.map((item, index) => {
                const detailsB = util.Compare(item, _this.data.payableTotal.details[index]);
                if (!detailsB) {
                    newData.totalDetails.push(_this.data.payableTotal.details[index]);
                }
            });

            this.oldData.payableTypes.map((item, index) => {
                const payableTypeB = util.Compare(item.payableType, _this.data.payableTypes[index].payableType);
                if (!payableTypeB) {
                    newData.payableTypes.push(_this.data.payableTypes[index].payableType);
                }
                item.payableTypeDetails.map((v, vIndex) => {
                    const detailsB = util.Compare(v, _this.data.payableTypes[index].payableTypeDetails[vIndex]);
                    if (!detailsB) {
                        newData.payableTypeDetails.push(_this.data.payableTypes[index].payableTypeDetails[vIndex]);
                    }
                });
            });
            this.$axios
                .post('/interfaceApi/report/payable/save', newData)
                .then(res => {
                    if (res) {
                        if(res.total){
                            this.data.payableTotal.total = res.total;
                        }
                        if(res.totalDetails.length>0){
                            res.totalDetails.map(item=>{
                                this.data.payableTotal.details = this.data.payableTotal.details.map(v=>{
                                    if(item.sort_num===v.sort_num){
                                        v = item;
                                    }
                                    return v;
                                });
                            });
                        }
                        if(res.payableTypes.length>0){
                            res.payableTypes.map(item=>{
                                this.data.payableTypes = this.data.payableTypes.map(v=>{
                                    if(v.payableType.sort_num === item.sort_num){
                                        v.payableType = item;
                                    }
                                    return v;
                                });
                            });
                        }
                        if(res.payableTypeDetails.length>0){
                            res.payableTypeDetails.map(item=>{
                                this.data.payableTypes = this.data.payableTypes.map(v=>{
                                    v.payableTypeDetails = v.payableTypeDetails.map(detailItem=>{
                                        if(item.payable_type === detailItem.payable_type&&item.sort_num === detailItem.sort_num){
                                            detailItem = item;
                                        }
                                        return detailItem;
                                    });
                                    return v;
                                });
                            });
                        }
                        this.$message.success('保存成功');
                        this.$emit('save-end', 'payable', 'success');
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                    this.$emit('save-end', 'sales', 'error');
                });
        },
        // 本年累计应付款--总计
        getTotal(name, type) {
            return this.$axios.get('/interfaceApi/report/payable/totalyear/' + this.reportMonth.report_id + '/' + name + '/' + type);
        },
        // 计算-总计
        // eslint-disable-next-line complexity
        async computTotalDetail(type, rowData) {
            if (type === 'pay') {
                if (rowData.pay) {
                    const res = await this.getTotal(rowData.type_name, 1);
                    if (res || res === 0) {
                        rowData.pay_year = parseFloat(rowData.pay) + res;
                        this.data.payableTotal.total.pay = 0;
                        this.data.payableTotal.total.pay_year = 0;
                        this.data.payableTotal.details.map(item => {
                            if (item.pay_year) {
                                this.data.payableTotal.total.pay_year = util.toFixed6(parseFloat(this.data.payableTotal.total.pay_year) + parseFloat(item.pay_year));
                            }
                            if (item.pay) {
                                this.data.payableTotal.total.pay = util.toFixed6(parseFloat(this.data.payableTotal.total.pay) + parseFloat(item.pay));
                            }
                        });
                        if (parseFloat(this.data.payableTotal.total.pay_year) && parseFloat(this.data.payableTotal.total.plan_year)) {
                            this.data.payableTotal.total.plan_year_finish
                            = util.toFixed6(parseFloat(this.data.payableTotal.total.pay_year)
                            / parseFloat(this.data.payableTotal.total.plan_year) * 100);
                        } else {
                            this.data.payableTotal.total.plan_year_finish = 0;
                        }
                        // 期末余额
                        if (!this.data.payableTotal.total.balance_begin) {
                            this.data.payableTotal.total.balance_begin = 0;
                        }
                        if (!this.data.payableTotal.total.procure_actual) {
                            this.data.payableTotal.total.procure_actual = 0;
                        }
                        if (!this.data.payableTotal.total.pay) {
                            this.data.payableTotal.total.pay = 0;
                        }
                        this.data.payableTotal.total.balance_end
                            = util.toFixed6(parseFloat(this.data.payableTotal.total.balance_begin)
                            + parseFloat(this.data.payableTotal.total.procure_actual)
                             - parseFloat(this.data.payableTotal.total.pay));
                        // 付款比例
                        if (parseFloat(this.data.payableTotal.total.pay_year) && parseFloat(this.data.payableTotal.total.procure_actual)) {
                            this.data.payableTotal.total.pay_rate = util.toFixed6(parseFloat(this.data.payableTotal.total.pay_year)
                            / parseFloat(this.data.payableTotal.total.procure_actual) * 100);
                        } else {
                            this.data.payableTotal.total.pay_rate = 0;
                        }
                        // 应付减少
                        if(!this.data.payableTotal.total.balance_begin) {
                            this.data.payableTotal.total.balance_begin = 0;
                        }
                        if(!this.data.payableTotal.total.balance_end) {
                            this.data.payableTotal.total.balance_end = 0;
                        }
                        this.data.payableTotal.total.pay_reduce = util.toFixed6(parseFloat(this.data.payableTotal.total.balance_begin)
                            - parseFloat(this.data.payableTotal.total.balance_end));
                    }
                }
            }
            if (type === 'totalPlanFinish') {
                if (this.data.payableTotal.total.pay_year && this.data.payableTotal.total.plan_year) {
                    this.data.payableTotal.total.plan_year_finish
                            = util.toFixed6(parseFloat(this.data.payableTotal.total.pay_year)
                            / parseFloat(this.data.payableTotal.total.plan_year) * 100);
                } else {
                    this.data.payableTotal.total.plan_year_finish = 0;
                }
            }
            if (rowData) {
                if (rowData.pay_year && rowData.plan_year) {
                    rowData.plan_year_finish = util.toFixed6(parseFloat(rowData.pay_year) / parseFloat(rowData.plan_year) * 100);
                } else {
                    rowData.plan_year_finish = 0;
                }
                if (rowData.pay_year_inner && rowData.plan_year_inner) {
                    rowData.plan_year_finish_inner = util.toFixed6(parseFloat(rowData.pay_year_inner) / parseFloat(rowData.plan_year_inner) * 100);
                } else {
                    rowData.plan_year_finish_inner = 0;
                }
            }
            if (type === 'pay_inner') {
                if (rowData.pay_inner) {
                    const res = await this.getTotal(rowData.type_name, 2);
                    if (res || res === 0) {
                        if(!rowData.pay_inner){
                            rowData.pay_inner = 0;
                        }
                        rowData.pay_year_inner = parseFloat(rowData.pay_inner) + res;
                        this.data.payableTotal.total.pay_inner = 0;
                        this.data.payableTotal.total.pay_year_inner = 0;
                        this.data.payableTotal.details.map(item => {
                            if (item.pay_year_inner) {
                                this.data.payableTotal.total.pay_year_inner = util.toFixed6(parseFloat(this.data.payableTotal.total.pay_year_inner) + parseFloat(item.pay_year_inner));
                            }
                            if (item.pay_inner) {
                                this.data.payableTotal.total.pay_inner = util.toFixed6(parseFloat(this.data.payableTotal.total.pay_inner) + parseFloat(item.pay_inner));
                            }
                        });
                        if (this.data.payableTotal.total.pay_year_inner && this.data.payableTotal.total.plan_year_inner) {
                            this.data.payableTotal.total.plan_year_finish_inner
                            = util.toFixed6(parseFloat(this.data.payableTotal.total.pay_year_inner)
                            / parseFloat(this.data.payableTotal.total.plan_year_inner) * 100);
                        } else {
                            this.data.payableTotal.total.plan_year_finish_inner = 0;
                        }
                        // 期末余额
                        if(!this.data.payableTotal.total.balance_begin_inner){
                            this.data.payableTotal.total.balance_begin_inner = 0;
                        }
                        if(!this.data.payableTotal.total.procure_actual_inner){
                            this.data.payableTotal.total.procure_actual_inner = 0;
                        }
                        if(!this.data.payableTotal.total.pay_inner){
                            this.data.payableTotal.total.pay_inner = 0;
                        }
                        this.data.payableTotal.total.balance_end_inner
                            = parseFloat(this.data.payableTotal.total.balance_begin_inner)
                            + parseFloat(this.data.payableTotal.total.procure_actual_inner)
                             - parseFloat(this.data.payableTotal.total.pay_inner);
                    }
                }
            }
            if (type === 'planFinishInner') {
                if (this.data.payableTotal.total.pay_year_inner && this.data.payableTotal.total.plan_year_inner) {
                    this.data.payableTotal.total.plan_year_finish_inner
                            = util.toFixed6(parseFloat(this.data.payableTotal.total.pay_year_inner)
                            / parseFloat(this.data.payableTotal.total.plan_year_inner) * 100);
                } else {
                    this.data.payableTotal.total.plan_year_finish_inner = 0;
                }
            }
        },
        // 本年累计应付款--总计
        getTotalType(type, name, field) {
            // eslint-disable-next-line max-len
            return this.$axios.get('/interfaceApi/report/payable/project/totalyear/' + this.reportMonth.report_id + '/' + type + '/' + name + '/' + field);
        },
        // 总计 
        computSumDetails(rowIndex) {
            let sumPay = 0;
            let sumPayYear = 0;
            let totalPay = 0;
            let totalPayYear = 0;
            this.data.payableTypes.map(item => {
                if (parseFloat(item.payableTypeDetails[rowIndex].pay)) {
                    sumPay = util.toFixed6(parseFloat(sumPay) + parseFloat(item.payableTypeDetails[rowIndex].pay));
                }
                if (parseFloat(item.payableTypeDetails[rowIndex].pay_year)) {
                    sumPayYear = util.toFixed6(parseFloat(sumPayYear) + parseFloat(item.payableTypeDetails[rowIndex].pay_year));
                }
                item.payableTypeDetails.map(v=>{
                    if(parseFloat(v.pay)){
                        totalPay = util.toFixed6(parseFloat(totalPay) + parseFloat(v.pay));
                    }
                    if(parseFloat(v.pay_year)){
                        totalPayYear = util.toFixed6(parseFloat(totalPayYear) + parseFloat(v.pay_year));
                    }
                });
            });
            this.data.payableTotal.details[rowIndex].pay = util.toFixed6(sumPay);
            this.data.payableTotal.details[rowIndex].pay_year = util.toFixed6(sumPayYear);
            this.data.payableTotal.total.pay = util.toFixed6(totalPay);
            this.data.payableTotal.total.pay_year = util.toFixed6(totalPayYear);
        },
        computSumDetailsInner(rowIndex) {
            let sumPay = 0;
            let sumPayYear = 0;
            let totalPay = 0;
            let totalPayYear = 0;
            this.data.payableTypes.map(item => {
                if (parseFloat(item.payableTypeDetails[rowIndex].pay_inner)) {
                    sumPay = util.toFixed6(parseFloat(sumPay) + parseFloat(item.payableTypeDetails[rowIndex].pay_inner));
                }
                if (parseFloat(item.payableTypeDetails[rowIndex].pay_year_inner)) {
                    sumPayYear = util.toFixed6(parseFloat(sumPayYear) + parseFloat(item.payableTypeDetails[rowIndex].pay_year_inner));
                }
                item.payableTypeDetails.map(v=>{
                    if(parseFloat(v.pay_inner)){
                        totalPay = util.toFixed6(parseFloat(totalPay) + parseFloat(v.pay_inner));
                    }
                    if(parseFloat(v.pay_year_inner)){
                        totalPayYear = util.toFixed6(parseFloat(totalPayYear) + parseFloat(v.pay_year_inner));
                    }
                });
            });
            this.data.payableTotal.details[rowIndex].pay_inner = sumPay;
            this.data.payableTotal.details[rowIndex].pay_year_inner = sumPayYear;
            this.data.payableTotal.total.pay_inner = totalPay;
            this.data.payableTotal.total.pay_year_inner = totalPayYear;
        },
        computTotalOther() {
            let balance_begin = 0;
            let balance_begin_inner = 0;
            let procure_actual = 0;
            let procure_actual_inner = 0;
            let balance_end = 0;
            let balance_end_inner = 0;
            let paycycle1 = 0;
            let paycycle3 = 0;
            let paycycle6 = 0;
            this.data.payableTypes.map(item=>{
                if(parseFloat(item.payableType.balance_begin)){
                    balance_begin = util.toFixed6(parseFloat(balance_begin) + parseFloat(item.payableType.balance_begin));
                }
                if(parseFloat(item.payableType.balance_begin_inner)){
                    balance_begin_inner = util.toFixed6(parseFloat(balance_begin_inner) + parseFloat(item.payableType.balance_begin_inner));
                }
                if(parseFloat(item.payableType.procure_actual)){
                    procure_actual = util.toFixed6(parseFloat(procure_actual) + parseFloat(item.payableType.procure_actual));
                }
                if(parseFloat(item.payableType.procure_actual_inner)){
                    procure_actual_inner = util.toFixed6(parseFloat(procure_actual_inner) + parseFloat(item.payableType.procure_actual_inner));
                }
                if(parseFloat(item.payableType.balance_end)){
                    balance_end = util.toFixed6(parseFloat(balance_end) + parseFloat(item.payableType.balance_end));
                }
                if(parseFloat(item.payableType.balance_end_inner)){
                    balance_end_inner = util.toFixed6(parseFloat(balance_end_inner) + parseFloat(item.payableType.balance_end_inner));
                }
                if(parseFloat(item.payableType.paycycle1)){
                    paycycle1 = util.toFixed6(parseFloat(paycycle1) + parseFloat(item.payableType.paycycle1));
                }
                if(parseFloat(item.payableType.paycycle3)){
                    paycycle3 = util.toFixed6(parseFloat(paycycle3) + parseFloat(item.payableType.paycycle3));
                }
                if(parseFloat(item.payableType.paycycle6)){
                    paycycle6 = util.toFixed6(parseFloat(paycycle6) + parseFloat(item.payableType.paycycle6));
                }
            });
            this.data.payableTotal.total.balance_begin = balance_begin;
            this.data.payableTotal.total.balance_begin_inner = balance_begin_inner;
            this.data.payableTotal.total.procure_actual = procure_actual;
            this.data.payableTotal.total.procure_actual_inner = procure_actual_inner;
            this.data.payableTotal.total.balance_end_inner = balance_end_inner;
            this.data.payableTotal.total.paycycle1 = paycycle1;
            this.data.payableTotal.total.paycycle3 = paycycle3;
            this.data.payableTotal.total.paycycle6 = paycycle6;
            this.data.payableTotal.total.balance_end = util.toFixed6(balance_begin + procure_actual -  parseFloat(this.data.payableTotal.total.pay));
            if(parseFloat(this.data.payableTotal.total.pay) && parseFloat(this.data.payableTotal.total.procure_actual)){
                this.data.payableTotal.total.pay_rate = util.toFixed6(parseFloat(this.data.payableTotal.total.pay)/parseFloat(this.data.payableTotal.total.procure_actual)*100);
            } else {
                this.data.payableTotal.total.pay_rate = 0;
            }
            this.data.payableTotal.total.pay_reduce = util.toFixed6(parseFloat(this.data.payableTotal.total.balance_begin) - parseFloat(this.data.payableTotal.total.balance_end
));            
        },
        // 计算-其他类型
        // eslint-disable-next-line complexity
        async computType(type, rowData, typeData, rowIndex) {
            const payableTypeObj = typeData.payableType;
            if (type === 'pay') {
                const res = await this.getTotalType(payableTypeObj.type_name, rowData.type_name, 1);
                if (res || res === 0) {
                    if (!rowData.pay) {
                        rowData.pay = 0;
                    }
                    rowData.pay_year = util.toFixed6(parseFloat(rowData.pay) + res);
                    payableTypeObj.pay = 0;
                    payableTypeObj.pay_year = 0;
                    typeData.payableTypeDetails.map(item => {
                        if (item.pay_year) {
                            payableTypeObj.pay_year = util.toFixed6(parseFloat(payableTypeObj.pay_year) + parseFloat(item.pay_year));
                        }
                        if (item.pay) {
                            payableTypeObj.pay = util.toFixed6(parseFloat(payableTypeObj.pay) + parseFloat(item.pay));
                        }
                    });
                    if (payableTypeObj.pay_year && payableTypeObj.plan_year) {
                        payableTypeObj.plan_year_finish
                        = util.toFixed6(parseFloat(payableTypeObj.pay_year)
                        / parseFloat(payableTypeObj.plan_year) * 100);
                    } else {
                        payableTypeObj.plan_year_finish = 0;
                    }
                    // 期末余额
                    if (!payableTypeObj.balance_begin) {
                        payableTypeObj.balance_begin = 0;
                    }
                    if (!payableTypeObj.procure_actual) {
                        payableTypeObj.procure_actual = 0;
                    }
                    if (!payableTypeObj.pay) {
                        payableTypeObj.pay = 0;
                    }
                    payableTypeObj.balance_end = util.toFixed6(parseFloat(payableTypeObj.balance_begin) + parseFloat(payableTypeObj.procure_actual) - parseFloat(payableTypeObj.pay));
                    // 付款比例
                    if (payableTypeObj.pay && payableTypeObj.procure_actual) {
                        payableTypeObj.pay_rate = util.toFixed6(parseFloat(payableTypeObj.pay)
                        / parseFloat(payableTypeObj.procure_actual) * 100);
                    } else {
                        payableTypeObj.pay_rate = 0;
                    }
                    // 应付减少
                    if (!payableTypeObj.balance_begin) {
                        payableTypeObj.balance_begin = 0;
                    }
                    if (!payableTypeObj.balance_end) {
                        payableTypeObj.balance_end = 0;
                    }
                    payableTypeObj.pay_reduce = util.toFixed6(parseFloat(payableTypeObj.balance_begin)
                        - parseFloat(payableTypeObj.balance_end));
                    // 计算 总计
                    this.computSumDetails(rowIndex);
                }
            }
            if (rowData) {
                if (rowData.pay_year && rowData.plan_year) {
                    rowData.plan_year_finish = util.toFixed6(parseFloat(rowData.pay_year) / parseFloat(rowData.plan_year) * 100);
                } else {
                     rowData.plan_year_finish = 0;
                }
                if (rowData.pay_year_inner && rowData.plan_year_inner) {
                    rowData.plan_year_finish_inner = util.toFixed6(parseFloat(rowData.pay_year_inner) / parseFloat(rowData.plan_year_inner) * 100);
                } else {
                    rowData.plan_year_finish_inner = 0;
                }
            }
            if (type === 'pay_inner') {
                const res = await this.getTotalType(rowData.payable_type, rowData.type_name, 2);
                    if (res || res === 0) {
                        if (!rowData.pay_inner) {
                            rowData.pay_inner = 0;
                        }
                        rowData.pay_year_inner = util.toFixed6(parseFloat(rowData.pay_inner) + res);
                        payableTypeObj.pay_inner = 0;
                        payableTypeObj.pay_year_inner = 0;
                        typeData.payableTypeDetails.map(item => {
                            if (item.pay_year_inner) {
                                payableTypeObj.pay_year_inner = util.toFixed6(parseFloat(payableTypeObj.pay_year_inner) + parseFloat(item.pay_year_inner));
                            }
                            if (item.pay_inner) {
                                payableTypeObj.pay_inner = util.toFixed6(parseFloat(payableTypeObj.pay_inner) + parseFloat(item.pay_inner));
                            }
                        });
                        if (payableTypeObj.pay_year_inner && payableTypeObj.plan_year_inner) {
                            payableTypeObj.plan_year_finish_inner
                            = util.toFixed6(parseFloat(payableTypeObj.pay_year_inner)
                            / parseFloat(payableTypeObj.plan_year_inner) * 100);
                        } else {
                            payableTypeObj.plan_year_finish_inner = 0;
                        }
                        // 期末余额
                        // if (payableTypeObj.balance_begin_inner && payableTypeObj.procure_actual_inner
                        // && payableTypeObj.pay_inner) {
                        //     payableTypeObj.balance_end_inner
                        //     = util.toFixed6(parseFloat(payableTypeObj.balance_begin_inner)
                        //     + parseFloat(payableTypeObj.procure_actual_inner)
                        //      - parseFloat(payableTypeObj.pay_inner));
                        // }
                        // 计算 总计
                        this.computSumDetailsInner(rowIndex);
                    }
            }
            if (type === 'totalPlanFinish') {
                if (payableTypeObj.pay_year && payableTypeObj.plan_year) {
                    payableTypeObj.plan_year_finish
                            = util.toFixed6(parseFloat(payableTypeObj.pay_year)
                            / parseFloat(payableTypeObj.plan_year) * 100);
                } else {
                    payableTypeObj.plan_year_finish = 0;
                }
            }
            if (type === 'planFinishInner') {
                if (payableTypeObj.pay_year_inner && payableTypeObj.plan_year_inner) {
                    payableTypeObj.plan_year_finish_inner
                            = util.toFixed6(parseFloat(payableTypeObj.pay_year_inner)
                            / parseFloat(payableTypeObj.plan_year_inner) * 100);
                } else {
                    payableTypeObj.plan_year_finish_inner = 0;
                }
            }
            // 计算 总计-其他字段
            this.computTotalOther();
        },
    },
    created() {
        this.getPayable();
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.wrapper{}
</style>